/* Animations */

.fade-in {
  opacity: 0;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

.fade-in-top {
  transform: translateY(24px);
}

.fade-in-bottom {
  transform: translateY(-24px);
}

.fade-in-left {
  transform: translateX(-24px);
}

.fade-in-right {
  transform: translateX(24px);
}

.fade-show {
  opacity: 1;
  transform: translateY(0);
}

/* Animation order classes */

.delay-100 { transition-delay: calc(200ms*1.7); }
.delay-200 { transition-delay: calc(300ms*1.7); }
.delay-300 { transition-delay: calc(400ms*1.7); }
.delay-400 { transition-delay: calc(500ms*1.7); }
.delay-500 { transition-delay: calc(600ms*1.7); }
.delay-600 { transition-delay: calc(700ms*1.7); }

