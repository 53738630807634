@media only screen and (max-width: 2140px) and (min-width: 1739px) {
    .statement-text {
      margin-right: 80px;
    }
  
    .solution-text {
      margin-left: 90px;
    }
  }
  
  @media only screen and (max-width: 1740px) and (min-width: 1540px) {
    .statement-text {
      margin-right: 60px;
    }
  
    .solution-text {
      padding-right: 20px;
    }
  }
  
  @media (max-width: 1199px) {
    .menu-overlay {
      display: block;
    }
  
    .header .menu-overlay {
      position: fixed;
      z-index: 9;
      background-color: rgba(0, 0, 0, 0.5);
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  
    .header .menu-overlay.active {
      visibility: visible;
      opacity: 1;
    }
  
    .header .nav-menu {
      position: fixed;
      right: -280px;
      visibility: hidden;
      top: 0;
      width: 280px;
      height: 100%;
      overflow-y: auto;
      z-index: 9;
      padding: 15px 0;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  
    .header .nav-menu.open {
      visibility: visible;
      right: 0;
    }
  
    .header .menu > .menu-item {
      display: block;
      margin: 0;
    }
  
    .header .header-btn > a,
    .header .menu > .menu-item > a {
      padding: 12px 15px;
      font-size: 18px;
      border-bottom: 1px solid #333;
    }
  
    .header .menu > .menu-item:first-child > a {
      border-top: 1px solid #333;
    }
  
    .header .menu > .menu-item > a .plus::before,
    .header .menu > .menu-item > a .plus::after {
      background-color: #fff;
    }
  
    .header .open-nav-menu,
    .nav-menu > .close-nav-menu {
      display: flex;
    }
  
    .header .open-nav-menu {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: end;
      margin: 0;
    }
  
    .header-btn.desk {
      display: none;
    }
  
    .header-btn.mob {
      display: block;
    }
  
    .header .header-btn > a.head-btn {
      margin: 0;
      background: none;
      padding: 12px 15px !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-backdrop-filter: none;
      backdrop-filter: none;
      text-align: left;
      border-radius: unset;
      max-width: 100%;
      border-bottom: 1px solid #333;
    }
  
    .header .header-btn > a.head-btn:hover {
      background: none;
      color: var(--primary_color);
    }
  }
  
  @media (max-width: 991px) {
    .sec-top-spacing {
      padding-top: 56px;
    }
  
    .sec-bottom-spacing {
      padding-bottom: 56px;
    }
  
    .heading-content h4 {
      margin: 0 0 40px 0;
    }
  
    .banefit-content h4 {
      margin: 0 0 32px 0;
    }
  
    .team-img,
    .investor-img {
      height: 426px;
    }
  
    .content-padding-left {
      padding: 0 0 0 50px;
    }
  
    .content-padding-right {
      padding: 0 50px 0 0;
    }
  
    section.work-sec {
      margin-top: 130px;
    }
  
    .contact-col {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
      flex: 0 0 45%;
    }
  }
  
  @media (max-width: 767px) {
    .logo a img {
      width: 130px;
    }
  
    .heading-content h4 {
      margin: 0 0 32px 0;
    }
  
    .statement-img {
      opacity: 0.4;
    }
  
    .statement-row {
      display: block;
    }
  
    .statement-left-col,
    .statement-right-col {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
    }
  
    .footer-sec {
      padding: 40px 0;
    }
  
    .statement-right-col {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      right: -210px;
      margin: auto;
      width: 490px;
      z-index: -1;
    }
  
    .statement-text {
      margin-right: 0;
      padding-left: 16px;
      padding-right: 16px;
    }
  
    .solution-left-col {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      right: -250px;
      margin: auto;
      width: 475px;
      z-index: -1;
      opacity: 0.4;
    }
  
    h2 {
      margin: 0 0 32px 0;
    }
  
    .popup-form {
      padding: 20px 25px 25px 25px;
      margin: 0 16px;
    }
  
    .popup-form form input + input {
      margin-top: 24px;
    }
  
    .popup-form-btn {
      margin: 34px 0 0 0;
      text-align: center;
    }
  
    .close-popup .btn-close {
      width: 0.2em;
      height: 0.2em;
    }
  
    .content-padding-left {
      padding: 0 0 0 20px;
    }
  
    .content-padding-right {
      padding: 0 20px 0 0;
    }
  
    .team-content h2 {
      margin: 0 0 32px 0;
    }
  
    .investor-content {
      margin: 32px 0 0 0;
    }
  
    .investor-content h2 {
      margin: 0 0 32px 0;
    }
  
    .terms-sec h2 {
      margin: 0 0 32px 0;
      text-align: left;
    }
  
    ul.work-list li .list-num span {
      width: 50px;
      height: 50px;
      font-size: 22px;
    }
  
    .list-text {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
    }
  
    .work-content h2 {
      margin: 0 0 32px 0;
    }
  
    .work-sec .row.sec-bg {
      padding: 50px 10px 50px 10px;
    }
  
    .icon-img {
      width: 64px;
      height: 64px;
    }
  
    section.statement-sec {
      margin: 56px 0 0 0;
    }
  
    .solution-text {
      padding-top: 0;
    }
  
    .contact-img {
      height: 350px;
    }
  
    .contact-wrap {
      gap: 50px 0;
    }
  
    .investor-sec .inner-heading-spacing {
      margin: 0 0 32px 0;
    }
  }
  
  @media (max-width: 575px) {
    .heading-content {
      text-align: left;
    }
  
    .about-heading {
      display: none;
    }
  
    .footer-menu-block ul {
      display: block;
    }
  
    .footer-menu-block ul li + li {
      margin-top: 8px;
    }
  
    .form-block {
      background: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      padding: 0;
    }
  
    .form-block h2 {
      text-align: left;
      margin: 0 0 32px 0;
    }
  
    .form-btn-sec {
      margin: 70px 0 0 0;
    }
  
    .investor-content {
      text-align: left;
    }
  
    .banefit-sec .row {
      gap: 24px 0;
    }
  
    .reverse {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }
  
    .team-img,
    .investor-img {
      height: 600px;
      margin: 0 0 30px 0;
    }
  
    .content-padding-left {
      padding: 0 0 0 0;
    }
  
    .content-padding-right {
      padding: 0 0 0 0;
    }
  
    .work-sec .row.sec-bg {
      padding: 50px 10px 350px 10px;
    }
  
    html {
      overflow-x: hidden;
    }
  
    .col-top {
      display: none;
    }
  
    section.work-sec {
      margin-top: 0;
    }
  
    .mob-img {
      margin: -280px 0 0 0;
      z-index: 1;
      position: relative;
    }
  
    .mob-img .work-img {
      height: 794px;
      max-width: 480px;
      margin: auto;
    }
  
    .mob-img .work-img img {
      width: 100%;
      height: 100%;
      display: block;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: left;
      object-position: left;
    }
  
    .mob-img .work-img.img-position img {
      -o-object-position: right;
      object-position: right;
    }
  
    .social-sec h2 {
      text-align: left;
    }
  
    .social-icons {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 15px;
    }
  
    .contact-img {
      height: 372px;
    }
  
    .contact-col {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
    }
  
    .contact-wrap {
      gap: 30px 0;
    }
  }
  
  @media (max-width: 380px) {
    .team-img,
    .investor-img {
      height: 372px;
    }
  }