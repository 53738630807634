@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --primary_color: #37B7C3;
    --secondary_color: #071952;
    --accent_color: #088395;
    --bright_color: #EBF4F6;
    --heading_color: #1e1e1e;
    --body_text_color: #4d5c64;
}

* {
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }


/* TYPOGRAPHY */
@font-face {
    font-family: 'PT Sans';
    src: url('./assets/fonts/PTSans-Regular.ttf') format("truetype");
}

/* @font-face {
    font-family: 'PT Sans';
    src: url('./assets/fonts/PTSans-Italic.ttf') format("truetype");
} */

@font-face {
    font-family: 'PT Sans';
    src: url('./assets/fonts/PTSans-BoldItalic.ttf') format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: 'PT Sans';
    src: url('./assets/fonts/PTSans-Bold.ttf') format("truetype");
    font-weight: 700;
}

body {
    line-height: 1.3;
    font-family: "PT Sans", sans-serif;
    font-weight: 400;
    color: var(--body_text_color);
    overflow-x: hidden;
  }


  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    color: var(--heading_color);
  }
  
  h1 {
    font-size: 64px;
    line-height: 1.25;
  }
  
  h2 {
    font-size: 56px;
    line-height: 1.14;
  }
  
  h3 {
    font-size: 46px;
    line-height: 1.2;
  }
  
  h4 {
    font-size: 32px;
    line-height: 1.3;
  }
  
  h5 {
    font-size: 26px;
    line-height: 1.2;
  }
  
  h6 {
    font-size: 22px;
    line-height: 1.2;
  }
  
  @media (max-width: 991px) {
    h1 {
      font-size: 54px;
    }
  
    h2 {
      font-size: 46px;
    }
  
    h3 {
      font-size: 36px;
    }
  
    h4 {
      font-size: 24px;
    }
  
    h6 {
      font-size: 20px;
    }
  }
  
  @media (max-width: 767px) {
    h1 {
      font-size: 48px;
    }
  
    h2 {
      font-size: 40px;
    }
  
    h3 {
      font-size: 30px;
    }
  
    h4 {
      font-size: 22px;
    }
  
    h6 {
      font-size: 20px;
    }
  }
  
  
  .section {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Buttons */

  .pro-btn {
    display: inline-block;
    background-color: var(--primary_color);
    color: #fff;
    padding: .8rem;
    font-size: 18px;
    font-weight: 700;
    border-radius: 45px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease-in-out, transform 0.5s ease-out calc(300ms*1.7), opacity 0.5s ease-out calc(300ms*1.7) !important;
  }

  .pro-btn:hover {
    background-color: #4f7d80;
  }
  
  /* end-project-button */
  
  .container {
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 16px;
    z-index: 1;
  }
  
  /* spacing */
  
  .sec-top-spacing {
    padding-top: 280px;
  }
  
  .sec-bottom-spacing {
    padding-bottom: 280px;
  }
  
  @media (max-width: 767px) {
    .sec-top-spacing {
      padding-top: 56px;
    }
  
    .sec-bottom-spacing {
      padding-bottom: 56px;
    }
  }
  
  /* end-section-spacing */
  
  .inner-heading-spacing {
    margin: 0 0 50px 0;
  }
  
  @media (max-width: 767px) {
    .inner-heading-spacing {
      margin: 0 0 32px 0;
    }
  }
  
  /* end-inner-heading-spacing */
  
  /* start-header-menu */
  
  .header {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    padding: 15px 0;
  }
  
  .header .logo {
    padding: 0;
  }
  
  .menu {
    position: relative;
  }
  
  .logo a img {
    width: 160px;
    height: 34px;
    display: block;
  }
  
  .header .logo a {
    font-size: 30px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 500;
  }
  
  .header .nav-menu {
    padding: 0 15px;
  }
  
  .header-main {
    display: flex;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    padding: 10px 0;
    border-radius: 4px;
  }
  
  .header .menu > .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header .header-btn > a,
  .header .menu > .menu-item > a,
  .header .menu > .menu-item > button {
    display: block;
    font-size: 22px;
    padding: 12px 0;
    color: #fff;
    text-transform: capitalize;
    font-weight: 700;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .header .header-btn > a > i {
    margin-left: 8px;
  }
  
  .header .open-nav-menu {
    height: 34px;
    width: 40px;
    margin-right: 15px;
    position: relative;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  /* animated outline */
  header .menu a {
    position: relative;
    color: #ffffff;
    text-decoration: none;
  }
  
  header .menu a:hover {
    color: #ffffff;
  }
  
  header .menu a::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  header .menu a:hover::before {
    transform: scaleX(1);
  }

  .header .open-nav-menu span {
    display: block;
    height: 3px;
    width: 37px;
    background-color: #fff;
    position: relative;
  }
  
  .header .open-nav-menu span::before,
  .header .open-nav-menu span::after {
    position: absolute;
    content: "";
    background-color: #fff;
    right: 0;
    height: 100%;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .header .open-nav-menu span::before {
    width: 31px;
    top: -8px;
  }
  
  .header .open-nav-menu span::after {
    width: 21px;
    top: 8px;
  }
  
  .close-menu {
    background-color: transparent;
    box-shadow: none;
    outline: none;
    border: none;
    color: #fff;
    transition: 400ms ease-in-out all;
    font-weight: 600;
    cursor: pointer;
  
    &:hover {
      color: var(--primary_color);
    }
  }
  
  .menu-overlay {
    display: none;
  }
    
  .header-btn {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  /* end-header-menu */
  
  /* start-banner-sec */
  
  .banner-first {
    background-image: url("./assets/images/about-banner-img.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 130px 0 130px 0;
    min-height: 776px;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 1;
  }
  
  .banner-sec {
    background-image: url("./assets/images/home-banner-img.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 130px 0 130px 0;
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 1;
  }
  
  .banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #4b555f;
    z-index: -1;
    mix-blend-mode: exclusion;
  }
  
  .banner-content {
    max-width: 650px;
  }
  
  .banner-content h1 {
    color: #fff;
    margin: 0 0 0 0;
    word-wrap: break-word;
  }
  
  /* end-banner-sec */
  
  /* start-inner-banner-sec */
  
  .inner-banner-sec {
    background-image: url("./assets/images/home-banner-img.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 200px;
  }
  
  /* end-inner-banner-sec */
  
  /* start-heading-section */
  
  .heading-content {
    text-align: center;
  }
  
  .heading-content h4 {
    text-transform: uppercase;
  }
  
  .heading-content h2 {
    margin: 0 0 0 0;
  }
  
  /* end-heading-section */
  
  /* start-work-section */
  
  section.work-sec {
    margin-top: 100px;
  }
  
  .work-sec .row.sec-bg {
    position: relative;
    z-index: 1;
  }
  
  .work-sec .row.sec-bg {
    padding: 64px;
    margin: 0 0 0 0;
    background: #f4f6f7;
    -webkit-box-shadow:
      0 2px 6px rgba(26, 26, 26, 0.04),
      0 8px 48px rgba(26, 26, 26, 0.09),
      0 4px 8px rgba(51, 73, 85, 0.13);
    box-shadow:
      0 2px 6px rgba(26, 26, 26, 0.04),
      0 8px 48px rgba(26, 26, 26, 0.09),
      0 4px 8px rgba(51, 73, 85, 0.13);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    border-radius: 40px;
  }
  
  .col-top {
    margin-top: -151px;
  }
  
  .work-content h2 {
    text-align: center;
    margin: 0 0 40px 0;
  }
  
  ul.work-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  ul.work-list li .list-num span {
    font-size: 20px;
    /* font-weight: 700; */
    color: #1e1e1e;
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 16px;
    -webkit-box-shadow:
      0 -1px 6px rgba(38, 50, 64, 0.07),
      0 6px 6px rgba(38, 50, 64, 0.05),
      0 20px 15px rgba(38, 50, 64, 0.01),
      0 15px 30px rgba(38, 50, 64, 0.06);
    box-shadow:
      0 -1px 6px rgba(38, 50, 64, 0.07),
      0 6px 6px rgba(38, 50, 64, 0.05),
      0 20px 15px rgba(38, 50, 64, 0.01),
      0 15px 30px rgba(38, 50, 64, 0.06);
  }
  
  ul.work-list li + li {
    margin: 40px 0 0 0;
  }
  
  .list-text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 88%;
    flex: 0 0 88%;
  }
  
  .list-num {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 11.5%;
    flex: 0 0 11.5%;
  }
  
  /* end-work-section */
  
  /* start-banefit-sec */
  
  .banefit-sec h2 {
    text-align: center;
  }
  
  .banefit-sec .row {
    gap: 30px 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .banefit-content h4 {
    margin: 0 40px 32px 0;
  }
  
  .banefit-content p {
    margin: 0 0 0 0;
  }
  
  /* end-banefit-sec */
  
  /* start-terms-section */
  
  .terms-sec h2 {
    text-align: center;
    margin: 0 0 40px 0;
  }
  
  .terms-content h4 {
    text-transform: uppercase;
    margin: 0 0 20px 0;
  }
  
  .terms-content li p {
    margin: 0 0 0 0;
    font-size: 22px;
  }
  
  .terms-content li + li {
    margin: 40px 0 0 0;
  }
  
  .terms-connect {
    margin: 40px 0 0 0;
    font-size: 22px;
  }
  
  .terms-connect a {
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  /* end-terms-section */
  
  /* start-social-section */
  
  .social-sec h2 {
    text-align: center;
  }
  
  .social-icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 48px;
  }
  
  .icon-img {
    width: 177px;
    height: 177px;
  }
  
  .icon-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  
  /* end-social-section */
  
  /* start-contact-section */
  
  .contact-sec .inner-heading-spacing {
    text-align: center;
  }
  
  .contact-col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 38%;
    flex: 0 0 38%;
  }
  
  .contact-img {
    height: 495px;
    margin: 0 0 32px 0;
  }
  
  .contact-img img {
    width: 100%;
    height: 100%;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
    backdrop-filter: blur(4px);
    border-radius: 16px;
  }
  
  .contact-content h2 {
    margin: 0 0 10px 0;
  }
  
  .contact-content a {
    color: var(--heading_color);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .contact-content a:hover {
    color: var(--primary_color);
  }
  
  /* end-contact-section */
  
  /* start-footer-section */
  
  .footer-sec {
    padding: 60px 0;
    background: #071952;
  }
  
  .footer-menu-block ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid #088395;
    padding: 0 0 32px 0;
    margin: 0 0 32px 0;
    gap: 8px 48px;
  }
  
  .footer-menu-block ul li a {
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .footer-menu-block ul li a:hover {
    color: #fff;
  }
  
  .footer-copyright-text p {
    color: #fff;
    font-size: 16px;
    margin: 0 0 0 0;
  }
  
  /* end-footer-section */

  /* Glass card */
  .glass-card {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
    border: 1px solid rgba(255, 255, 255, 0.33);
  }